import {
  encode as base64_encode,
  // decode as base64_decode,
} from 'base-64';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
// import { BoxProps } from '@mui/material';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import RealLogo from '../../assets/logo-real.png';
import MetamaskLogo from '../../assets/logo-metamask-new.png';
import LinkIcon from '../../assets/icon-link-new.png';

import { useEffect } from "react";
import { coinbaseWallet, getAccount } from "../../libs/coinbase";

const MainBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  paddingTop: '30px',
  width: '100%',
  height: '100%',
});

const TitleContainer = styled(Box)({
  width: '100%',
  height: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '104px'
})

const NavTitle = styled(Typography)({
  fontSize: '18px',
  color: '#050505',
  lineHeight: '18px',
  letterSpacing: '1px',
  fontFamily: 'FuBlod',
});

const IconsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: '64px',
})

const LogoBox = styled(Box)({
  width: '80px',
  height: '80px',
  borderRadius: '16px',
  overflow: 'hidden',
});

const IconBox = styled(Box)({
  width: '44px',
  height: '44px',
  margin: '0 24px',
});

const Title = styled(Typography)({
  fontSize: '18px',
  color: '#050505',
  lineHeight: '25px',
  fontFamily: 'FuBlod',
  letterSpacing: '1px',
  marginBottom: '4px',
});

const SubTitle = styled(Typography)({
  fontSize: '12px',
  color: '#8A8F97',
  lineHeight: '15px',
  fontFamily: 'FuMiddle',
  marginBottom: '32px',
});

const SignAndroid = (): JSX.Element => {

  useEffect(() => {
    getCoinbaseAccount();
  }, []);

  const getCoinbaseAccount = async () => {
    try {
      const account = await getAccount();
      console.log('%c🀅 account', 'color: #733d00; font-size: 20px;', account);
      const data = JSON.stringify({
        wallet_address: account,
        error: '',
      })
      // @ts-ignore
      window.location = `real://dapp?${base64_encode(data)}`;
    } catch (error) {
      alert(error);
      // @ts-ignore
      window.location = `real://dapp?${base64_encode(JSON.stringify({wallet_address: '', error, }))}`
    }
  }

  return (
    <>
      <Container fixed sx={{ backgroundColor: '#FFFFFF', height: '100vh', padding: 0, }}>
        <MainBox>
          <TitleContainer>
            <NavTitle>
              Connect Wallet
            </NavTitle>
          </TitleContainer>

          <IconsContainer>
            <LogoBox>
              <img src={RealLogo} alt="REAl" style={{width: '100%', height: '100%', display: 'block',}} />
            </LogoBox>
            <IconBox>
              <img src={LinkIcon} alt="Link" style={{width: '100%', height: '100%', display: 'block',}} />
            </IconBox>
            <LogoBox>
              <img src={MetamaskLogo} alt="Metamask" style={{width: '100%', height: '100%', display: 'block',}} />
            </LogoBox>
          </IconsContainer>

          <Title>
            Connect wallet to REAL
          </Title>
          <SubTitle>
            Ethereum Mainnet
          </SubTitle>

          {/* <ConnectButton isConnecting={isConnecting} onClick={sign}>
            {
              isConnecting ? 'Connecting…' : 'Connect'
            }
          </ConnectButton> */}
        </MainBox>
      </Container>
    </>
  );
}

export default SignAndroid;
