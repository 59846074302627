import { useEffect, useState } from 'react';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import BoouniverseLogo from '../../assets/logo-boounverise.png';
import MetaMaskTool from '../../libs/metamask';

const MainBox = styled(Box)({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  paddingTop: '80px',
  backgroundImage: `url(${require('../../assets/buy-bg.png')})`,
  backgroundSize: '100% 100%',
  backgroundPosition: 'top center',
  backgroundRepeat: 'no-repeat',
});

const LogoBox = styled(Box)({
  width: '72px',
  height: '72px',
});

const BottomBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingBottom: '22px',
})

const ImageBox = styled(Box)({
  width: '300px',
  height: '300px',
  marginBottom: '136px',
})

const MintButton = styled(Button)({
  width: '252px',
  height: '44px',
  fontSize: '14px',
  lineHeight: '16px',
  color: '#000000',
  padding: '0',
  borderRadius: '25.5px',
  textTransform: 'none',
  backgroundImage: 'linear-gradient(to right, #33FBD4, #3BEE50)',
  fontFamily: 'MENormal',
});

const Title = styled(Typography)({
  fontSize: '12px',
  lineHeight: '18px',
  color: '#FFFFFF',
  marginBottom: '20px',
  fontFamily: 'MENormal',
});

const Sologen = styled(Typography)({
  fontSize: '10px',
  lineHeight: '18px',
  color: '#A6A6A6',
  marginTop: '8px',
  fontFamily: 'MENormal',
});

const MintDNA = (): JSX.Element => {
  const [id, setId] = useState('');
  const [img, setImage] = useState('');

  const mint = async () => {
    try {
      await MetaMaskTool.instance.connect();
    } catch (error) { }
    try {
      await MetaMaskTool.instance.mintDNA(id);
      // @ts-ignore
      window.location = `https://www.boouniverse.io/${base64_encode(JSON.stringify({ result: true }))}`;
    } catch (error) {
      // @ts-ignore
      window.location = `https://www.boouniverse.io/${base64_encode(JSON.stringify({ result: false }))}`;
    }
  }

  useEffect(() => {
    try {
      // @ts-ignore
      const params: any = JSON.parse(base64_decode(window.location.search.replace('?params=', '')));
      if (params['id']) {
        setId(params['id']);
      }
      if (params['img_url']) {
        setImage(params['img_url'])
      }
    } catch (error) { }
  }, []);

  return (
    <>
      <Container fixed sx={{ backgroundColor: '#151515', minHeight: '100vh', padding: 0, }}>
        <MainBox>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}
          >
            <LogoBox>
              <img style={{ width: '100%', height: '100%', display: 'block', }} src={BoouniverseLogo} alt="Logo" />
            </LogoBox>
            <Sologen>
              Boo Universe
            </Sologen>
          </Box>

          <BottomBox>
            <ImageBox>
              {
                img ? <img style={{ width: '100%', height: '100%', display: 'block', }} src={img} alt="DNA" />
                  : <></>
              }
            </ImageBox>
            <Title>
              Boo Universe Gene
            </Title>
            <MintButton onClick={mint}>
              Mint
            </MintButton>
          </BottomBox>
        </MainBox>
      </Container>
    </>
  );
}

export default MintDNA;
