import CoinbaseWalletSDK from '@coinbase/wallet-sdk'

const APP_NAME = ''
const APP_LOGO_URL = ''
const DEFAULT_ETH_JSONRPC_URL = 'https://mainnet.infura.io/v3/b8150b34db124a0684431796b55b39a8'
const DEFAULT_CHAIN_ID = 1

export const coinbaseWallet = new CoinbaseWalletSDK({
  appName: APP_NAME,
  appLogoUrl: APP_LOGO_URL,
  darkMode: false
})

export const ethereum = coinbaseWallet.makeWeb3Provider(DEFAULT_ETH_JSONRPC_URL, DEFAULT_CHAIN_ID);

export const getAccount = () => new Promise((resolve, reject) => {
  ethereum.request({ method: 'eth_requestAccounts' }).then(response => {
    const accounts : string[] = response as string[];
    console.log(`User's address is ${accounts[0]}`)
    resolve(accounts[0]);
  });
});
