import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import CssBaseline from '@material-ui/core/CssBaseline';
import BuyWearable from './pages/BuyWearable';
import MintDNA from './pages/MintDNA';
import Sign from './pages/Sign';
import './App.scss';
import './fonts/font.scss';
import 'react-toastify/dist/ReactToastify.css';
import SignAndroid from './pages/Sign/android';

function App() {
  return (
    <>
      <Router>
        <CssBaseline />
        <Routes>
          <Route path='/' element={<></>} />
          <Route path='/sign' element={<Sign />} />
          <Route path='/signCoinbase' element={<SignAndroid />} />
          <Route path='/mint-dna' element={<MintDNA />} />
          <Route path='/buy-wearable' element={<BuyWearable />} />
        </Routes>
        <ToastContainer position="top-right" autoClose={3000} hideProgressBar={true} theme="dark" />
      </Router>
    </>
  );
}

export default App;
